.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}
#custom-make-ant-table-scrollable {
  min-width: 700px;
  /* min-width: 800px; */
  /* width: 800px; */
  overflow-x: scroll !important;
}
#custom-make-ant-table-scrollable-width-600 {
  min-width: 600px;
}

#custom-make-ant-table-scrollable-width-800 {
  min-width: 800px;
}

#custom-make-ant-table-scrollable-width-700 {
  min-width: 700px;
}

#custom-make-ant-table-scrollable-width-900 {
  min-width: 900px;
}

#custom-make-ant-table-scrollable-width-1050 {
  min-width: 1050px;
}
.userProfile{
  left: 88% !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.content{
  margin: 14px 16px;
  background: rgb(255, 255, 255);
  overflow: auto;
  padding: 24px;
  overflow-x: hidden;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.customF {
  overflow: hidden;
}
.customF .ant-form-item-label {
  display: inline-block;
    overflow: hidden;
    line-height: 39.9999px;
    white-space: nowrap;
    text-align: left;
    vertical-align: middle;
}
.payment-modal {
  overflow-y: scroll;
}
.status-lable{
  display: flex;
  margin-top: 2vw !important;
}
.status-lable .ant-col{
  margin-right: 15px  !important;
}
@media (max-width: 575px){

.status-lable .ant-form-item-label, .ant-form-item-control-wrapper {
    display: block;
    width: none  !important

}
}

.status::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.menuFlex .ant-form-item-label{
  text-align: justify;
}
.sitetable .ant-table {
  font-size: 12px;  
}
.ant-table-wrapper.sitetable .ant-table .ant-table-body :is(th, td) {
  padding: 6px 4px !important;
}
.ant-table-wrapper.sitetable .ant-table .ant-table-body-outer :is(th, td) {
  padding: 6px 4px !important;
}

.date-range {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  width: 400px;
  /* text-align: center; */
}

.date {
  font-weight: bold;
  color: #333;
}

