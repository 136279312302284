.sitelist-table .ant-table-wrapper .ant-table-body-outer .ant-table-fixed .ant-table-tbody .ant-table-row.ant-table-row-level-0 .ant-table-column-has-actions.ant-table-column-has-filters{
    position: relative;
}

.lastAccess-div .site-lastAccess{
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 12.4px;
    color: black;
    margin-right: 4px;
}